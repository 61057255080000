import { ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from '@environments/environment';
import { hmrBootstrap } from './hmr';
import { AppComponent } from './app/app.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormModule as CapturumFormlyModule } from '@capturum/formly';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CoreModule } from '@core/core.module';
import { AvailableFavicons, favicons } from '@core/configs/favicon.config';
import { NgxFaviconModule } from 'ngx-favicon';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { SharedModule } from '@capturum/shared';
import { TranslateService, TranslateModule, MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';
import { HOURS_BLOCK_OPTIONS, getHourblockOptions } from '@core/utils/timeslots.utils';
import { CustomDialogService } from '@core/services/custom-dialog.service';
import { CustomApiHttpService } from '@core/services/custom-api-http.service';
import { ApiHttpService, ApiModule } from '@capturum/api';
import { CustomAuthService } from '@core/services/custom-auth.service';
import { CustomUserService } from '@core/services/custom-user.service';
import { UserService, TranslationLoader, CompleteModule } from '@capturum/complete';
import { CustomLoginService } from '@core/services/custom-login.service';
import { LoginService, LoginModule } from '@capturum/login';
import { CustomErrorMessageInterceptor } from '@core/interceptors/custom-error-message.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CapturumDialogService } from '@capturum/ui/api';
import { UnauthenticatedGuard, AuthService, AuthModule } from '@capturum/auth';
import { PublicTranslationResolver } from '@core/resolvers/public-translation.resolver';
import { PermissionResolver } from '@core/resolvers/permission.resolver';
import { TranslationResolver } from '@core/resolvers/translation.resolver';
import { SettingResolver } from '@core/resolvers/setting.resolver';
import { ModuleResolver } from '@core/resolvers/module.resolver';
import { BaseDataResolver } from '@core/resolvers/base-data.resolver';
import { CompleteResolver } from '@core/resolvers/complete.resolver';
import { ConfirmationService } from 'primeng/api';
import 'hammerjs';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { createErrorHandler, init } from '@sentry/angular-ivy';

registerLocaleData(localeNl);

function onAuthError(): void {
  // Do nothing
}

if (environment.production) {
  enableProdMode();
}

if (environment.sentryUrl) {
  init({
    dsn: environment.sentryUrl,
    environment: environment.name,
    normalizeDepth: 0,
    ignoreErrors: [
      'UnknownError ',
      'Handled unknown error',
      'NoSuchDatabaseError',
      'Non-Error exception captured',
      'NG0100: ExpressionChangedAfterItHasBeenCheckedError',
      'Failed to execute \'put\' on \'IDBObjectStore\'',
      // PDF viewer errors
      'startCleanup',
      'Cannot read properties of null (reading \'parentNode\')',
      'Transport destroyed',
    ],
  });
}

const bootstrap = () => bootstrapApplication(AppComponent, {
    providers: [
        {
          provide: ErrorHandler,
          useValue: createErrorHandler({
            showDialog: false,
          }),
        },
        importProvidersFrom(SharedModule, BrowserModule, AppRoutingModule, TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoader,
            },
        }), NgxPermissionsModule.forRoot(), AuthModule.forRoot({
            baseUrl: environment.baseUrl,
            production: environment.production,
            authRejectionRoute: AppRoutes.authLogin,
            unauthenticatedRejectionRoute: AppRoutes.portal,
            authService: CustomAuthService,
        }), ApiModule.forRoot({
            baseUrl: environment.baseUrl,
            production: environment.production,
            onAuthError,
        }), LoginModule.forRoot({
            environment,
            productName: 'RU | Booking Platform',
            redirectAfterLogin: AppRoutes.portal,
            logoImageUrl: '/assets/images/radboud/logo.png',
            backgroundImageUrl: '/assets/images/radboud/background.png',
            defaultLanguage: 'en',
        }), NgxFaviconModule.forRoot<AvailableFavicons>({
            faviconElementId: 'favicon',
            defaultUrl: 'assets/images/radboud/favicon.ico',
            custom: favicons,
        }), CompleteModule.forRoot({
            indexedDbModels: [],
            databaseName: 'radboud.complete',
            version: 1,
            environment: environment,
        }, NgxPermissionsService, NgxPermissionsModule), CoreModule, CapturumToastModule, ConfirmDialogModule, CapturumFormlyModule, TooltipModule),
        ConfirmationService,
        CompleteResolver,
        BaseDataResolver,
        ModuleResolver,
        SettingResolver,
        TranslationResolver,
        PermissionResolver,
        PublicTranslationResolver,
        UnauthenticatedGuard,
        CapturumDialogService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomErrorMessageInterceptor,
            multi: true,
        },
        {
            provide: LoginService,
            useClass: CustomLoginService,
        },
        {
            provide: UserService,
            useClass: CustomUserService,
        },
        {
            provide: AuthService,
            useClass: CustomAuthService,
        },
        {
            provide: ApiHttpService,
            useClass: CustomApiHttpService,
        },
        {
            provide: CapturumDialogService,
            useClass: CustomDialogService,
        },
        {
            provide: HOURS_BLOCK_OPTIONS,
            useFactory: getHourblockOptions,
            deps: [TranslateService],
        },
    ]
});

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, () => bootstrap().then((ngModuleRef: any) => ngModuleRef));
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
}
