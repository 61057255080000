<div class="page-wrapper__heading">
  <ng-container *ngIf="loading; else content">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <cap-skeleton height="50px" width="400px"></cap-skeleton>

      <div class="d-flex justify-content-between align-items-center">
        <cap-skeleton height="50px" width="200px"></cap-skeleton>

        <div class="ml-3">
          <cap-skeleton height="50px" width="200px"></cap-skeleton>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #content>
    <ng-container *ngIf="showBack; else defaultHeader">
      <div class="page-wrapper__heading__link" (click)="onBackClick.emit()">
        <h1><i class="fas fa-arrow-left mr-4"></i> {{ heading }}</h1>
      </div>
    </ng-container>

    <ng-template #defaultHeader>
      {{ heading }}
    </ng-template>

    <div *ngIf="extraContentTemplate">
      <ng-container *ngTemplateOutlet="extraContentTemplate"></ng-container>
    </div>

    <div class="page-wrapper__heading__buttons" *ngIf="buttons?.length || menuVisible || navigationVisible">
      <ng-container *ngFor="let button of buttons">
        <ng-container *ngxPermissionsOnly="button?.permissions">
          <cap-button
            *ngIf="(button?.hide | observablePipe | async) === false"
            [styleClass]="button?.styleClass"
            (click)="button.callback()"
            [icon]="button?.icon"
            [iconPos]="button?.iconPos"
            [label]="button?.label">
          </cap-button>
        </ng-container>
      </ng-container>

      <app-menu-button
        *ngIf="menuItems && menuItems?.length > 0 && menuVisible"
        [isLoading]="isExporting"
        [menuItems]="menuItems">
      </app-menu-button>

      <app-navigation-button *ngIf="navigationVisible" [navigationButton]="navigationButton"></app-navigation-button>
    </div>
  </ng-template>
</div>
