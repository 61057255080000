import { BUILD_TIMESTAMP } from './timestamp';
const packageJson = require('../../package.json');

export const environment = {
  appName: 'Booking Science',
  production: false,
  hmr: false,
  name: 'accept',
  baseUrl: 'https://central-taxi.k8s-accept.emendis.nl/api',
  sentryUrl: 'https://00d4ecd61dd04e77866e48c170e87bdd@o4504360297496576.ingest.sentry.io/4504377873989632',
  databaseName: 'radboud.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://booking-science.acceptatie.emendis.nl'
};
