import { Injectable } from '@angular/core';
import { AuthConfig, AuthService as CapturumAuthService } from '@capturum/auth';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomAuthService extends CapturumAuthService {
  constructor(private configuration: AuthConfig, private httpClient: HttpClient) {
    super(configuration, httpClient);
  }

  /**
   * @inheritDoc
   */
  public logout(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const token = this.getToken();

      if (!token) {
        // Don't try to logout if token is already gone
        observer.next(true);
        observer.complete();
      } else {
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        };

        this.httpClient
          .post(`${this.configuration.baseUrl}/auth/logout`, null, { headers })
          .pipe(catchError(this.handleThrownError))
          .subscribe(
            (response) => {
              this.removeStaleCredentials();
              this.setAuthenticationState(false);

              observer.next(true);
              observer.complete();
            },
            (error) => {
              observer.error(error);
              observer.complete();
            },
          );
      }
    });
  }

  /**
   * Handle the thrown error
   *
   * @param error: any
   * @return Observable<never>
   */
  private handleThrownError(error: any): Observable<never> {
    return throwError(error);
  }
}
