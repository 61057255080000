import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
  template: `
    <ng-container *ngFor="let option of to.options | observablePipe | async">
      <div class="d-block pb-2">
        <p-radioButton
          [name]="key"
          [value]="option.value"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [class.ng-dirty]="showError"
          [required]="to.required || false"
          [label]="option.label | observablePipe | async">
        </p-radioButton>
      </div>
    </ng-container>
  `,
  standalone: true,
  imports: [NgFor, RadioButtonModule, FormsModule, ReactiveFormsModule, FormlyModule, AsyncPipe, CapturumSharedModule],
})
export class RadioFormlyTypeComponent extends FieldType implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      // Bug in PrimeNG radioButton with reactive forms: https://github.com/primefaces/primeng/issues/9162
      this.formControl.setValue(value, { emitEvent: false });
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
