<nav class="navbar navbar-static-top white-bg header d-flex align-items-center" role="navigation">
  <div class="search-bar px-4">
    <form *ngIf="form" [formGroup]="form">
      <cap-input
        [placeholder]="'radboud.header.search.placeholder' | translate"
        formControlName="search"
        iconLeft="fas fa-search">
      </cap-input>
    </form>
  </div>

  <div class="flex-grow-1"></div>

  <div class="menu">
    <div *capIfAuthenticated class="menu-item profile">
      <div class="d-flex align-items-center">
        <div class="menu-item-label d-none d-sm-block mr-3 text-right">
          <span>{{ currentUser?.full_name }}</span>
          <span class="d-block domains-list" *ngIf="userDomains$ | async as domains">{{ domains.join(', ') }}</span>
        </div>

        <span class="fa-stack fa-2x" [routerLink]="routes.profile" routerLinkActive="active">
          <i class="fas fa-square fa-stack-2x"></i>
          <i class="fas fa-user fa-stack-1x fa-inverse"></i>
        </span>
      </div>
    </div>

    <div class="menu-item logout">
      <span *ngIf="(authenticationState$ | async) === true" (click)="logout()" class="fa-stack fa-2x">
        <i class="fas fa-square fa-stack-2x"></i>
        <i class="fas fa-sign-out fa-stack-1x fa-inverse"></i>
      </span>

      <span
        *ngIf="(authenticationState$ | async) === false"
        (click)="overlayPanel.toggle($event)"
        class="fa-stack fa-2x">
        <i class="fas fa-square fa-stack-2x"></i>
        <i class="fas fa-sign-in fa-stack-1x fa-inverse"></i>
      </span>
    </div>
  </div>
</nav>

<p-overlayPanel styleClass="radboud-overlaypanel" [style]="{ width: '400px' }" appendTo="body" #overlayPanel>
  <div class="panel">
    <div class="menu-item row" (click)="overlayPanel.overlayVisible = false; routeToLogin()">
      <div class="col-12 d-flex align-items-center">
        <i class="d-inline mr-3 fas fa-globe color-primary"></i>

        <div class="d-flex flex-column">
          <span class="title d-inline" [class.active]="true">
            {{ 'radboud.auth.local-login.title' | translate }}
          </span>

          <span class="subtitle d-inline">
            {{ 'radboud.auth.local-login.subtitle' | translate }}
          </span>
        </div>
      </div>
    </div>

    <div class="menu-item row" (click)="overlayPanel.overlayVisible = false; loginWithSURFconext()">
      <div class="col-12 d-flex align-items-center">
        <img style="width: 29px" class="mr-2" [src]="'surfconext.png' | assets: 'images'" alt="surfconext" />

        <div class="d-flex flex-column">
          <span class="title d-inline" [class.active]="true">
            {{ 'radboud.auth.surfconext.title' | translate }}
          </span>

          <span class="subtitle d-inline">
            {{ 'radboud.auth.surfconext.subtitle' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>
