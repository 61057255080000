import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { Domain } from '@features/domain/models/domain.model';
import { DomainService } from '@features/domain/services/domain.service';
import { responseData } from '@core/utils/converter.utils';
import { ListOptions } from '@capturum/api';
import { map, takeUntil } from 'rxjs/operators';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Accordion, AccordionModule } from 'primeng/accordion';
import packageJson from './../../../../../package.json';
import { CompleteModule } from '@capturum/complete';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthModule } from '@capturum/auth';
import { SharedModule } from 'primeng/api';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    AccordionModule,
    NgFor,
    SharedModule,
    RouterLinkActive,
    AuthModule,
    NgxPermissionsModule,
    CapturumSkeletonModule,
    AsyncPipe,
    TranslateModule,
    CompleteModule,
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('accordion')
  public accordion: Accordion;

  public version = packageJson.version;
  public routes: typeof AppRoutes = AppRoutes;
  public domains$: Observable<Domain[]>;
  public managePermissions: string[];
  public activeIndex: number;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private domainService: DomainService, private cdr: ChangeDetectorRef, private router: Router) {}

  public ngOnInit(): void {
    this.getDomains();

    this.managePermissions = [
      'radboud.user.show',
      'radboud.user.manage',
      'radboud.category.show',
      'radboud.category.manage',
      'radboud.costplace.show',
      'radboud.costplace.manage',
      'radboud.department.show',
      'radboud.department.manage',
      'radboud.device.show',
      'radboud.device.manage',
      'radboud.device-group.show',
      'radboud.device-group.manage',
      'radboud.domain.show',
      'radboud.domain.manage',
      'radboud.group.show',
      'radboud.group.manage',
      'radboud.info.show',
      'radboud.info.manage',
      'radboud.institute.show',
      'radboud.institute.manage',
      'radboud.mail.show',
      'radboud.mail.manage',
      'radboud.report.show',
      'radboud.report.manage',
      'radboud.send-mail.show',
      'radboud.send-mail.manage',
      'radboud.setting.show',
      'radboud.setting.manage',
      'radboud.translation.show',
      'radboud.translation.manage',
    ];

    this.domainService
      .getDomainsUpdated()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getDomains();
        this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resetAccordion(event: MouseEvent): void {
    this.accordion.tabs.forEach((tab) => {
      if (tab.selected) {
        tab.toggle(event);
      }
    });
  }

  /**
   * Get the domains
   *
   * @return void
   */
  private getDomains(): void {
    const options: ListOptions = {
      include: ['categories'],
      perPage: 9999,
    };

    this.domains$ = this.domainService.index(options).pipe(
      responseData,
      map((domains: Domain[]) => {
        const portalDomain = domains.find((domain) => {
          return domain.is_portal_page;
        });
        const sorted = domains
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
          .map((domain) => {
            domain.routerLink = `/domain/${encodeURIComponent(domain.name)}`;

            domain.categories = domain.categories.map((category) => {
              category.routerLink = `${domain.routerLink}/category/${encodeURIComponent(category.short_name)}`;

              return category;
            });

            return domain;
          })
          .filter((domain) => {
            return !domain.is_portal_page;
          });
        const sortedDomains = [portalDomain, ...sorted];

        if (this.router.url.includes('portal')) {
          this.activeIndex = 0;
        } else {
          sortedDomains.forEach((domain, index) => {
            this.activeIndex = decodeURIComponent(this.router.url).replace(/%20/g, ' ').includes(domain?.name)
              ? index
              : this.activeIndex;
          });
        }

        return sortedDomains;
      }),
    );
  }
}
