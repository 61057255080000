enum GeneralRoutes {
  auth = 'auth',
  portal = 'portal',
  manage = 'manage',
  information = 'information',
}

enum AuthRoutes {
  authLogin = 'auth/login',
  authSurfConextLogin = 'auth/login-surfconext',
  authForgot = 'auth/forgot',
  authReset = 'auth/reset/:token/:email',
}

enum UserRoutes {
  profile = 'profile',
  settings = 'settings',
  userBooking = 'my-bookings',
}

enum CommonRoutes {
  overview = 'overview',
  add = 'add',
  edit = 'edit',
}

enum EntityRoutes {
  domain = 'domain',
  category = 'category',
  globalSearch = 'global-search',
  device = 'device',
  booking = 'booking',
  department = 'department',
  institute = 'institute',
  user = 'user',
  setting = 'setting',
  translation = 'translation',
  costplace = 'costplace',
  sendMail = 'send-mail',
  deviceGroup = 'device-group',
  project = 'project',
  reference = 'reference',
  report = 'report',
  company = 'company',
  info = 'info',
  bookingApproval = 'booking-approval',
  location = 'location',
  monsterBooking = 'monster-booking',
  timeslot = 'timeslot',
  role = 'role',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...GeneralRoutes,
  ...AuthRoutes,
  ...UserRoutes,
  ...CommonRoutes,
  ...EntityRoutes,
};
