import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumInputModule } from '@capturum/ui/input';

@Component({
  template: `
    <cap-input
      [type]="to.type || 'text'"
      [class]="to.styleClass"
      [placeholder]="to.translatePlaceholder || to.placeholder | observablePipe | async"
      [formlyAttributes]="field"
      [autocomplete]="to.autocomplete || 'off'"
      [formControl]="formControl"
      [required]="to.required || false | observablePipe | async"
      [iconLeft]="to.iconLeft || null"
      [iconRight]="to.iconRight || null"
      [class.ng-dirty]="showError">
      <ng-container *ngIf="to.contentLeftText">
        <ng-template capTemplate="content-left">
          <span>{{ to.contentLeftText }}</span>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="to.contentRightText">
        <ng-template capTemplate="content-right">
          <span>{{ to.contentRightText }}</span>
        </ng-template>
      </ng-container>
    </cap-input>

    <small *ngIf="to.hint" [innerHTML]="to.hint"></small>
  `,
  standalone: true,
  imports: [CapturumInputModule, FormlyModule, FormsModule, ReactiveFormsModule, NgIf, CapturumSharedModule, AsyncPipe],
})
export class InputFormlyTypeComponent extends FieldType {}
