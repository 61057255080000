<div class="selectable-items-list">
  <ng-container *ngIf="formArray && formArray?.controls && formArray?.controls?.length > 0; else noContent">
    <div *ngFor="let control of formArray?.controls; let i = index" class="selectable-items-list__item">
      <ng-container [ngSwitch]="type">
        <div *ngSwitchCase="'dropdown'" class="selectable-items-list__item__dropdown hide-validator">
          <cap-dropdown
            [formControl]="control.get(controlName)"
            [options]="availableItems"
            [placeholder]="(placeholder ? placeholder : 'placeholder.select') | translate"
            [label]="entityLabel + ' ' + (i + 1)"
            [disabled]="disabled"
            [virtualScroll]="true">
          </cap-dropdown>
        </div>

        <div *ngSwitchCase="'input'" class="selectable-items-list__item__input hide-validator">
          <cap-input
            [formControl]="control.get(controlName)"
            [label]="entityLabel + ' ' + (i + 1)"
            [disabled]="disabled">
          </cap-input>
        </div>
      </ng-container>

      <i
        *ngIf="(i > 0 || showDeleteFirst) && !disabled"
        class="selectable-items-list__item__trash fas fa-trash"
        (click)="onRemove.emit(i)">
      </i>
    </div>
  </ng-container>

  <ng-template #noContent>
    <span *ngIf="disabled" class="selectable-items-list__no-content">
      {{ 'radboud.items.no-content.label' | translate }}
    </span>
  </ng-template>

  <cap-button
    *ngIf="!disabled"
    [label]="'radboud.button.add-entity.label' | translate : { entity: entityLabel?.toLowerCase() }"
    (click)="onAdd.emit()"
    icon="fas fa-plus"
    iconPos="left"
    styleClass="secondary mt-3">
  </cap-button>
</div>
