export enum BaseDataKey {
  deviceStatus = 'device-status',
  bookingStatus = 'booking-status',
  userCategory = 'user-category',
  referenceType = 'reference-type',
  reportType = 'report-type',
  reportUnitType = 'report-unit-type',
  bookingReferenceType = 'booking-reference-type',
  deviceGroupUserRole = 'device-group-user-role',
}
