<div class="search-card">
  <div class="search-card__header">
    {{ title }}
  </div>

  <div class="search-card__content">
    <cap-input
      *ngIf="searchControl"
      [formControl]="searchControl"
      iconLeft="fas fa-search"
      [placeholder]="inputPlaceholder">
    </cap-input>

    <div class="search-card__content__results mt-2">
      <ng-container *ngIf="(loading$ | async) === false; else loader">
        <ng-container *ngIf="devices.length; else noResults">
          <div *ngFor="let item of devices" class="search-card__content__results__item">
            <ng-container [ngTemplateOutlet]="resultCardTemplate" [ngTemplateOutletContext]="{ item: item }">
            </ng-container>
          </div>
        </ng-container>

        <ng-template #noResults>
          <span *ngIf="hasSearched" class="search-card__content__no-results">
            {{ 'radboud.global-search.no-results.label' | translate }}
          </span>
        </ng-template>
      </ng-container>

      <ng-template #loader>
        <app-loader styleClass="mx-auto"></app-loader>
      </ng-template>
    </div>
  </div>
</div>
