import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormUtils } from '@core/utils/form.utils';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumCardModule } from '@capturum/ui/card';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CapturumCardModule, CapturumSharedModule, FormlyModule],
})
export class FormCardComponent implements OnDestroy {
  public get submitted(): boolean {
    return this._submitted;
  }

  @Input()
  public set submitted(value: boolean) {
    this._submitted = value;

    if (value) {
      FormUtils.markAsTouched(this.form);
      this.cdr.detectChanges();
    }
  }

  public get form(): UntypedFormGroup {
    return this._form;
  }

  @Input()
  public set form(value: UntypedFormGroup) {
    this._form = value;

    if (value) {
      this.subscription = this.form.statusChanges.subscribe(() => {
        this.cdr.detectChanges();
      });
    }
  }

  @Input() public icon: string;
  @Input() public title: string;
  @Input() public model: any;
  @Input() public fields: FormlyFieldConfig[];
  @Input() public options: FormlyFormOptions;
  @Input() public mode = 'vertical';
  private _submitted: boolean;

  private _form: UntypedFormGroup;
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
