import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { ErrorMessageInterceptor as CapturumErrorMessageInterceptor } from '@capturum/complete';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ExportJobService } from '../services/export-job.service';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorMessageInterceptor extends CapturumErrorMessageInterceptor implements HttpInterceptor {
  public maintenanceMessageShown = false;

  constructor(
    public router: Router,
    public toastService: ToastService,
    public translateService: TranslateService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private exportJobService: ExportJobService,
  ) {
    super(router, toastService, translateService, authService);
  }

  public defaultParsingErrors(err: HttpErrorResponse): void {
    if (err.status === 503 && !this.maintenanceMessageShown) {
      this.maintenanceMessageShown = true;

      this.toastService.error('Unavailable', 'The application is currently unavailable due to maintenance', {
        sticky: true,
      });
    } else {
      super.defaultParsingErrors(err);
    }
  }

  /**
   * @inheritDoc
   */
  public notAuthorizeHandle(err: HttpErrorResponse): void {
    if (this.authService.getToken()) {
      this.authService
        .logout()
        .pipe(first())
        .subscribe(() => {
          this.exportJobService.stopExportJob();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/'], { relativeTo: this.route }).then(() => {
              this.toastService.warning(
                this.translateService.instant('radboud.auth.session-expired.title'),
                this.translateService.instant('radboud.auth.session-expired.label'),
                { life: 7000 },
              );

              localStorage.clear();
            });
          });
        });
    } else if (this.router.url.includes('/auth/login') && err.error?.error === 'expired') {
      this.toastService.warning(this.translateService.instant('link.login'), err.error.message, { life: 7000 });
    }
  }
}
