<div *ngFor="let field of field.fieldGroup; let i = index; let isFirst = first" class="repeatable-section-list__item">
  <formly-field class="repeatable-section-list__item__input" [field]="field"></formly-field>

  <div class="repeatable-section-list__item__trash" *ngIf="!form.disabled">
    <i class="fas fa-trash" (click)="remove(i)"> </i>
  </div>
</div>
<div *ngIf="!form.disabled">
  <cap-button
    [label]="'radboud.button.add-entity.label' | translate : { entity: to.addEntityLabel?.toLowerCase() }"
    (click)="add()"
    icon="fas fa-plus"
    iconPos="left"
    styleClass="secondary mt-3">
  </cap-button>
</div>
