<div class="device-availability mt-4 w-100">
  <div class="device-availability__wrapper">
    <div class="device-availability__wrapper__header d-flex align-items-end">
      {{ 'radboud.device.availability.week.label' | translate : { week: weekNumber } }}

      <i
        class="fas fa-info-circle color-primary ml-2 device-availability__wrapper__header__info-icon"
        (click)="legendPanel.toggle($event)">
      </i>

      <cap-calendar
        *ngIf="detailPage"
        appendTo="body"
        styleClass="radboud-inline-calendar"
        [inline]="false"
        [selectionMode]="'single'"
        [defaultDate]="defaultDate"
        [(ngModel)]="date"
        [readonlyInput]="true"
        [dateFormat]="'dd-mm-yy'"
        [minDate]="defaultDate"
        [showWeek]="true"
        (select)="dateChange.emit($event)">
      </cap-calendar>
    </div>

    <div *ngIf="days && days.length > 0; else loader" class="device-availability__wrapper__body">
      <div class="row mx-0">
        <div class="col-sm-10 offset-sm-2 device-availability__wrapper__body__hours d-flex flex-nowrap">
          <div *ngFor="let hour of hours" class="device-availability__wrapper__body__hours__hour">
            {{ hour }}
          </div>
        </div>
      </div>

      <div *ngFor="let day of days" class="row mx-0 device-availability__wrapper__body__day">
        <div class="col-2 col-xs-2 d-flex justify-content-between align-items-center pl-0">
          <span class="device-availability__wrapper__body__day__name">{{ day?.dayName }}</span>
          <span class="device-availability__wrapper__body__day__separator">|</span>
          <span class="device-availability__wrapper__body__day__format">{{ day?.dayFormat }}</span>
        </div>

        <div class="col-10 col-xs-10 d-flex p-0 justify-content-between align-items-center">
          <div *ngFor="let item of day?.hours" class="device-availability__wrapper__body__day__cube">
            <div
              *ngFor="let hour of item.hour"
              class="device-availability__wrapper__body__day__cube__mask cube-{{
                hour?.status || 'booking-status-available'
              }} cube-left-{{ hour?.maskPosition }} cube-percentage-{{ hour?.percentage || '100' }}"
              (click)="onHourClick(hour, $event)"
              [class.pointer]="!hour?.hide && !detailPage"></div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loader>
      <app-loader styleClass="p-5"></app-loader>
    </ng-template>
  </div>
</div>

<p-overlayPanel styleClass="radboud-overlay-panel" appendTo="body" #legendPanel>
  <ng-template pTemplate>
    <div *ngIf="bookingStatuses$ | async as statuses" class="radboud-overlay-panel__list">
      <div *ngFor="let status of statuses" class="radboud-overlay-panel__list__item">
        <i
          class="radboud-overlay-panel__list__item__icon fas mr-3 icon-{{ status?.value }}"
          [ngClass]="status?.value === 'booking-status-available' ? 'fa-check-circle' : 'fa-times-circle'">
        </i>

        {{ status?.id | capBaseDataTranslation }}
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel styleClass="radboud-overlay-panel radboud-overlay-panel-tooltip" appendTo="body" #tooltipPanel>
  <ng-template pTemplate *capIfAuthenticated>
    <div class="radboud-overlay-panel-tooltip__header">
      <div class="radboud-overlay-panel-tooltip__header__title">
        <ng-container *ngIf="focusedBooking?.blocked">
          {{ 'radboud.device.availability.device-blocked.label' | translate }}
        </ng-container>

        <ng-container *ngIf="!focusedBooking?.blocked">
          {{ 'radboud.device.availability.device-booked.label' | translate }}
        </ng-container>
      </div>

      <div *ngIf="currentUser" class="radboud-overlay-panel-tooltip__header__subtitle">
        {{
          'radboud.device.availability.booked-by.label' | translate : { user: focusedBooking?.user?.contact_full_name }
        }}

        <ng-container *ngIf="currentUser?.id === focusedBooking?.user?.id">
          {{ 'radboud.device.availability.you.label' | translate }}
        </ng-container>
      </div>
    </div>

    <div class="radboud-overlay-panel-tooltip__content">
      <div class="radboud-overlay-panel-tooltip__content__left">
        <div class="radboud-overlay-panel-tooltip__content__left__icon">
          <i class="fas fa-calendar"></i>
        </div>

        <div class="radboud-overlay-panel-tooltip__content__left__text">
          {{ focusedBooking | bookingDate : 'EEEE,  dd - MM - yyyy' : 'EEEE,  dd - MM - yyyy' }}
        </div>
      </div>

      <div class="radboud-overlay-panel-tooltip__content__right">
        <div class="radboud-overlay-panel-tooltip__content__right__icon">
          <i class="fas fa-clock"></i>
        </div>

        <div class="radboud-overlay-panel-tooltip__content__right__text">
          {{ focusedBooking?.period_start | safeDate : 'HH:mm': false }}
          -
          {{ focusedBooking?.period_end | safeDate : 'HH:mm': false }}
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
