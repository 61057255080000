import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ButtonMenuItem } from '@core/models/button-menu-item.model';
import { CapturumSharedModule } from '@capturum/ui/api';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CapturumButtonModule,
    OverlayPanelModule,
    NgFor,
    NgxPermissionsModule,
    AsyncPipe,
    CapturumSharedModule,
  ],
})
export class MenuButtonComponent {
  @Input() public showMenu = true;
  @Input() public menuItems: ButtonMenuItem[];
  @Input() public isLoading = false;
}
