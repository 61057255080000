<div class="action ml-2" *ngIf="showMenu && menuItems && menuItems?.length > 0">
  <cap-button
    styleClass="primary cap-icon-button open-menu-button"
    [disabled]="isLoading"
    [icon]="isLoading ? 'fas fa-spinner fa-pulse' : 'fas fa-ellipsis-v'"
    (click)="!button.disabled ? overlayPanel.toggle($event) : null"
    #button>
  </cap-button>
</div>

<p-overlayPanel styleClass="menu-button--overlaypanel p-0" [style]="{ width: '350px' }" appendTo="body" #overlayPanel>
  <div class="panel">
    <ng-container *ngFor="let item of menuItems">
      <ng-container *ngIf="(item.hide | observablePipe | async) === false">
        <ng-container *ngxPermissionsOnly="item.permissions">
          <div class="menu-item row" (click)="overlayPanel.overlayVisible = false; item?.callback()">
            <div class="col-12 d-flex align-items-center">
              <i class="d-inline mr-3" [class]="item?.icon"></i>

              <div class="d-flex flex-column">
                <span class="title d-inline" [class.active]="true">
                  {{ item?.title | observablePipe | async }}
                </span>

                <span *ngIf="item?.subtitle" class="subtitle d-inline">
                  {{ item?.subtitle | observablePipe | async }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</p-overlayPanel>
