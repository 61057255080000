import { Component, ViewChild } from '@angular/core';
import { MapItem, CapturumSharedModule } from '@capturum/ui/api';
import { CapturumMultiSelectComponent, CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { LabelFromOptionsPipe } from '../../../pipes/label-from-options.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  template: `
    <ng-container *ngIf="to.options | observablePipe | async as options">
      <cap-multi-select
        [options]="options"
        *ngIf="!to.disabled"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [defaultLabel]="to.translatePlaceholder || to.placeholder | observablePipe | async"
        [disabled]="to.disabled"
        [label]="to.translateMultiselectLabel | observablePipe | async"
        [inputId]="field.key"
        [sortAlphabetically]="to.sortAlphabetically"
        [filter]="to.filter || defaultOptions.templateOptions.filter"
        [filterBy]="to.filterBy || defaultOptions.templateOptions.filterBy"
        [filterPlaceHolder]="to.filterPlaceHolder"
        [appendTo]="to.appendTo"
        (change)="to.change ? to.change(field, $event) : null"
        [scrollHeight]="to.scrollHeight || defaultOptions.templateOptions.scrollHeight"
        [maxSelectedLabels]="0"
        [displaySelectedLabel]="to.displaySelectedLabel || true"
        [readonly]="to.readOnly"></cap-multi-select>
      <h5 class="group-selected-options-title" *ngIf="!to.disabled">
        {{ to.translateSelectedItemsTitle | observablePipe | async }}
      </h5>

      <ng-container *ngIf="formControl.value as selectedItems">
        <ul *ngIf="selectedItems.length; else noContent" class="group-selected-options-list">
          <li *ngFor="let item of selectedItems" class="group-selected-options-item">
            <span>{{ item | labelFromOptions : options }}</span>

            <i *ngIf="!to.disabled" (click)="remove(item, $event)" class="fas fa-trash color-danger"> </i>
          </li>
        </ul>
      </ng-container>

      <ng-template #noContent>
        <span class="no-content">
          {{ 'radboud.select-card.no-items-selected.label' | translate }}
        </span>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['multi-select-group.formly-type.scss'],
  standalone: true,
  imports: [
    NgIf,
    CapturumMultiSelectModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    AsyncPipe,
    TranslateModule,
    CapturumSharedModule,
    LabelFromOptionsPipe,
  ],
})
export class MultiSelectGroupedFormlyTypeComponent extends FieldType {
  public defaultOptions = {
    templateOptions: {
      filter: true,
      filterBy: 'label',
      appendTo: null,
      scrollHeight: '200px',
      overlayVisible: false,
      dataKey: 'value',
      defaultLabel: 'Choose',
      onChange: null,
    },
  };

  @ViewChild(CapturumMultiSelectComponent)
  public multiselect: CapturumMultiSelectComponent;

  public remove(item: MapItem, event: MouseEvent): void {
    this.multiselect.pMultiSelect.onOptionSelect({
      originalEvent: event,
      option: item,
    });
  }
}
