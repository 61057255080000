import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '@capturum/complete';
import { BaseListComponent } from '@capturum/shared';
import { ToastService as BlueprintToastService, FilterMatchMode, TableAction } from '@capturum/ui/api';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { LoadingService } from '@capturum/ui/loader';
import { Entities } from '@core/enums/general/entities.enum';
import { ExportType } from '@core/enums/general/export-type.enum';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { ButtonMenuItem } from '@core/models/button-menu-item.model';
import { FilterConfig } from '@core/models/filter-config.model';
import { ManageHeaderButton } from '@core/models/manage-header-button.model';
import { BlobService } from '@core/services/blob.service';
import { ResourceApiService } from '@core/services/resource-api.service';
import { TranslateService } from '@ngx-translate/core';
import { DatesFormats } from '@src/app/core/enums/general/dates-formats.enum';
import { firstLetterUpperCase } from '@src/app/core/utils/converter.utils';
import { format } from 'date-fns';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, FilterMetadata } from 'primeng/api';
import { EMPTY, forkJoin, from, interval, Observable, Subject } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  template: '',
  standalone: true,
})
export class ManageListComponent<T> extends BaseListComponent<T> implements OnInit, OnDestroy {
  @ViewChild(CapturumInfoTableComponent)
  public infoTable: CapturumInfoTableComponent;

  public canShowMenu$: boolean | Observable<boolean>;
  public menuItems: ButtonMenuItem[];
  public tableColumns: InfoTableColumn[];
  public tableActions: (TableAction & { callback: (item: any) => void })[];
  public filterConfig: FilterConfig[];
  public routes: typeof AppRoutes = AppRoutes;
  public buttons: ManageHeaderButton[];
  public deleteMethod = 'delete';
  public identifier = 'id';
  public isExportable = true;
  public isExporting = false;
  public shouldTranslateError = true;

  protected entity: Entities;
  protected subs: SubSink = new SubSink();
  protected router: Router;
  protected route: ActivatedRoute;
  protected confirmService: ConfirmationService;
  protected ngxPermissionsService: NgxPermissionsService;
  protected settingService: SettingService;
  protected loadingService: LoadingService;
  protected exportBlobService: BlobService;
  protected blueprintToastService = inject(BlueprintToastService);

  constructor(public injector: Injector) {
    super(injector, injector.get(TranslateService));

    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.confirmService = injector.get(ConfirmationService);
    this.ngxPermissionsService = injector.get(NgxPermissionsService);
    this.settingService = injector.get(SettingService);
    this.loadingService = injector.get(LoadingService);
    this.exportBlobService = injector.get(BlobService);
  }

  public ngOnInit(): void {
    this.buttons = this.getDefaultButtons();
    this.menuItems = this.getDefaultMenuItems();
    this.canShowMenu$ = this.getCanShowMenu();

    if (this.isExportable) {
      this.menuItems = [...this.menuItems, ...this.getExportMenuItems()];
    }
  }

  public ngOnDestroy(): void {
    this.clearTableSelection();

    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  /**
   * Route back to manage page
   *
   * @return void
   */
  public routeBack(): void {
    this.router.navigate([AppRoutes.manage]);
  }

  /**
   * Add an instance of the model
   *
   * @return void
   */
  public add(): void {
    this.router.navigate([`../${AppRoutes.add}`], { relativeTo: this.route });
  }

  /**
   * Edit the model
   *
   * @param id: string
   * @return void
   */
  public edit(id: string): void {
    this.router.navigate([`../${id}`], { relativeTo: this.route });
  }

  /**
   * Filter globally
   *
   * @param value: string
   * @return void
   */
  public filterGlobal(value: string): void {
    this.search = [value.replace(' ', '%')];

    this.infoTable.primeNGTable.filterGlobal(value, FilterMatchMode.LIKE);
  }

  /**
   * Filter the table
   *
   * @param event: { value: any, field: string, matchMode: string }
   * @return void
   */
  public filter(event: { value: any; field: string; matchMode: string }): void {
    this.infoTable.filterTable(event.value, event.field, event.matchMode as FilterMatchMode);
  }

  /**
   * Globally filter the table
   *
   * @param event: string
   * @return void
   */
  public searchGlobal(event: string): void {
    this.onSearchEvent(event, true);
  }

  public applyFilters(event: Record<string, FilterMetadata>): void {
    this.search = null;

    if (event.global?.value) {
      this.search = [event.global.value.replace(' ', '%')];
    }

    this.infoTable.primeNGTable.filters = event;
    // eslint-disable-next-line no-underscore-dangle
    this.infoTable.primeNGTable._filter();
  }

  /**
   * Reset the table filters
   *
   * @return void
   */
  public reset(): void {
    this.sort = null;
    this.search = null;
    this.infoTable.primeNGTable.reset();

    if (this.infoTable.primeNGTable.stateKey) {
      this.infoTable.primeNGTable.clearState();
    }
  }

  /**
   * Delete bulk items
   *
   * @return void
   */
  public deleteBulk(): void {
    if (!this.infoTable?.selectedRows?.length || this.infoTable?.selectedRows?.length <= 0) {
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('radboud.table.no-items-selected.label'),
      );

      return;
    }

    const entityName = this.translateService.instant(`radboud.${this.entity}.entity_name_plural`).toLowerCase();

    this.confirmationService.confirm({
      header: this.translateService.instant('confirm.title'),
      message: this.translateService.instant('radboud.manage.confirm-delete-entity.label', {
        entity: entityName,
      }),
      accept: () => {
        this.loadingService.toggleLoading(true);
        this.loading = true;

        const observables = this.infoTable.selectedRows.map((item: T) => {
          return this.apiService[this.deleteMethod](item[this.identifier]);
        });

        forkJoin(observables).subscribe(
          () => {
            this.loadingService.hideLoader();
            this.loading = false;
            this.resetSelection();

            this.toastService.success(
              this.translateService.instant('toast.success.title'),
              this.translateService.instant('radboud.table.delete-bulk-success.label'),
            );

            this.loadTableDataFromCurrentLazyLoadEvent();
          },
          (error) => {
            this.loading = false;
            this.loadingService.hideLoader();
          },
        );
      },
    });
  }

  /**
   * Get visibility of menu
   *
   * @return boolean | Observable<boolean>
   */
  public getCanShowMenu(): boolean | Observable<boolean> {
    return true;
  }

  /**
   * @inheritDoc
   */
  public onSearchEvent(searchStr: string, reloadFlag: boolean): void {
    this.search = [searchStr.replace(' ', '%')];

    if (reloadFlag) {
      this.filterGlobal(searchStr);
    }
  }

  /**
   * Get active filters
   *
   * @return { field: string, value: string, operator: string }[]
   */
  public getActiveFilters(): { field: string; value: string; operator: string }[] {
    return Object.keys(this.infoTable?.activeFilters).map((key) => {
      const item: FilterMetadata = this.infoTable.activeFilters[key] as FilterMetadata;

      if (
        Array.isArray(item.value) &&
        item.value?.some((value) => {
          return value instanceof Date;
        })
      ) {
        item.value = item.value.map((date) => {
          return format(new Date(date), DatesFormats.defaultDate);
        });
      }

      return {
        field: key,
        value: item.value,
        operator: item.matchMode,
      };
    });
  }

  /**
   * Get active search
   *
   * @return string[]
   */
  public getActiveSearch(): string[] {
    return this.search;
  }

  public deleteItem(id: number | string, item: string = this.itemNames.singular): void {
    if (this.apiService === null) {
      return;
    }

    // make first letter uppercase
    const entity = firstLetterUpperCase(this.entity);
    const message = this.translateService.instant('toast.success.delete', {
      entity,
    });

    this.loading = true;

    this.apiService.delete(id).subscribe({
      next: () => {
        this.tableVisible = false;

        this.loadTableData(this.lazyLoadEvent);

        this.toastService.success(item, message);
      },
      error: (error) => {
        this.handleError(error);

        this.loading = false;
      },
    });
  }

  /**
   * Get the default delete entity table row action
   *
   * @return (TableAction & { callback: (item: any) => void })
   */
  protected getDeleteEntityTableRowAction(): TableAction & { callback: (item: any) => void } {
    const entityName = this.translateService.instant(`radboud.${this.entity}.entity_name`).toLowerCase();

    return {
      label: this.translateService.instant('radboud.manage.delete-entity.label', {
        entity: entityName,
      }),
      icon: 'fas fa-trash',
      callback: (item) => {
        this.deleteItemWithConfirm(
          item.id,
          this.translateService.instant('confirm.title'),
          this.translateService.instant('radboud.manage.confirm-delete-entity.label', {
            entity: entityName,
          }),
        );
      },
      hidden: from(this.ngxPermissionsService.hasPermission(`radboud.${this.entity}.manage`)).pipe(
        map((authorized) => {
          return !authorized;
        }),
      ),
      permissions: [`radboud.${this.entity}.manage`],
    };
  }

  /**
   * Get the default header buttons
   *
   * @return ManageHeaderButton[]
   */
  protected getDefaultButtons(): ManageHeaderButton[] {
    return [
      {
        label: this.translateService.instant(`radboud.manage-${this.entity}.add.label`),
        styleClass: 'primary',
        callback: () => {
          return this.add();
        },
        icon: 'fas fa-plus',
        iconPos: 'left',
        permissions: [`radboud.${this.entity}.manage`],
        hide: false,
      },
    ];
  }

  /**
   * Get the default menu items
   *
   * @return ButtonMenuItem[]
   */
  protected getDefaultMenuItems(): ButtonMenuItem[] {
    const entityName = this.entity === 'user' ? 'superuser' : this.entity;

    return [
      {
        title: this.translateService.instant('radboud.table.delete-selected-items.label'),
        icon: 'fas fa-trash',
        hide: false,
        permissions: [`radboud.${entityName}.manage`],
        callback: () => {
          return this.deleteBulk();
        },
      },
    ];
  }

  protected getExportMenuItems(): ButtonMenuItem[] {
    return [
      {
        title: this.translateService.instant('radboud.report.export-to-pdf.label'),
        icon: 'fas fa-file-pdf',
        hide: false,
        permissions: [`radboud.${this.entity}.manage`],
        callback: () => {
          if (!this.infoTable.primeNGTable.isEmpty()) {
            return this.export(ExportType.pdf);
          } else {
            this.toastService.warning(
              this.translateService.instant('radboud.response.no-content.title'),
              this.translateService.instant('radboud.response.no-content.label'),
            );
          }
        },
      },
      {
        title: this.translateService.instant('radboud.report.export-to-csv.label'),
        icon: 'fas fa-file-csv',
        hide: false,
        permissions: [`radboud.${this.entity}.manage`],
        callback: () => {
          if (!this.infoTable.primeNGTable.isEmpty()) {
            return this.export(ExportType.csv);
          } else {
            this.toastService.warning(
              this.translateService.instant('radboud.response.no-content.title'),
              this.translateService.instant('radboud.response.no-content.label'),
            );
          }
        },
      },
      {
        title: this.translateService.instant('radboud.report.export-to-excel.label'),
        icon: 'fas fa-file-excel',
        hide: false,
        permissions: [`radboud.${this.entity}.manage`],
        callback: () => {
          if (!this.infoTable.primeNGTable.isEmpty()) {
            return this.export(ExportType.xlsx);
          } else {
            this.toastService.warning(
              this.translateService.instant('radboud.response.no-content.title'),
              this.translateService.instant('radboud.response.no-content.label'),
            );
          }
        },
      },
    ];
  }

  /**
   * Clear the table selected rows
   *
   * @return void
   */
  protected clearTableSelection(): void {
    if (this.infoTable) {
      this.infoTable.selectedRows = [];
    }
  }

  /**
   * @inheritDoc
   */
  protected handleError(response: HttpErrorResponse): void {
    super.handleError(response);

    this.tableVisible = true;
  }

  /**
   * Hook after bulk delete
   *
   * @return void
   */
  protected afterBulkDelete(): void {
    //
  }

  /**
   * @return void
   */
  protected resetSelection(): void {
    this.infoTable.primeNGTable.clearState();
    this.clearTableSelection();
    this.afterBulkDelete();
  }

  protected export(type: string): void {
    this.isExporting = true;

    const successfulExport = new Subject<boolean>();
    const ids = this.infoTable.selectedRows
      ? this.infoTable.selectedRows
          .map((row) => {
            return row.id;
          })
          .join(',')
      : '';

    const listOptions = {
      search: this.getActiveSearch(),
      filters: this.getActiveFilters(),
      sort: this.getListOptions().sort,
      parameters: [
        {
          field: 'ids',
          value: ids,
        },
      ],
    };

    (this.apiService as ResourceApiService<T>)
      .export(type, listOptions)
      .pipe(
        catchError((response) => {
          this.handleExportError(response);

          return EMPTY;
        }),
      )
      .subscribe({
        next: (result: Blob) => {
          if (result && result.size) {
            successfulExport.next(true);
            successfulExport.complete();

            this.toastService.success(
              this.translateService.instant('toast.success.title'),
              this.translateService.instant('radboud.report.export.success'),
            );
          }

          const entityName = this.translateService.instant(`radboud.${this.entity}.entity_name_plural`).toLowerCase();

          this.exportBlobService.downloadBlobAsFile(result, type, `${entityName}.${type}`);
        },
        complete: () => {
          this.isExporting = false;

          this.cdr.detectChanges();
        },
      });
  }

  protected exportByJob(type: string): void {
    const successfulExport = new Subject<boolean>();
    const listOptions = {
      search: this.getActiveSearch(),
      filters: this.getActiveFilters(),
    };

    (this.apiService as ResourceApiService<T>)
      .exportByJob(type, listOptions)
      .pipe(
        first(),
        switchMap(({ export_id }) => {
          return interval(3000).pipe(
            switchMap(() => {
              return (this.apiService as ResourceApiService<T>).exportJobDownload(type, export_id);
            }),
            takeUntil(successfulExport),
          );
        }),
      )
      .subscribe({
        next: (result: Blob) => {
          if (result && result.size) {
            successfulExport.next(true);
            successfulExport.complete();

            const entityName = this.translateService.instant(`radboud.${this.entity}.entity_name_plural`).toLowerCase();

            this.exportBlobService.downloadBlobAsFile(result, type, `${entityName}.${type}`);
          }
        },
        error: () => {
          this.isExporting = false;

          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('radboud.report.message.use-filters'),
          );
        },
        complete: () => {
          this.isExporting = false;

          this.cdr.detectChanges();
        },
      });
  }

  private handleExportError(errorResponse: HttpErrorResponse): void {
    if (
      errorResponse instanceof HttpErrorResponse &&
      errorResponse.error instanceof Blob &&
      errorResponse.headers.get('Content-Type') === 'application/json'
    ) {
      const reader = new FileReader();

      reader.addEventListener('loadend', (event: ProgressEvent) => {
        const errorFromBlob: { error: Record<string, string> } = { error: JSON.parse(event.target['result']) };

        this.blueprintToastService.error(
          this.translateService.instant('toast.error.title'),
          errorFromBlob.error.error,
          {
            sticky: true,
          },
        );
      });

      reader.readAsText(errorResponse.error);
    }
  }
}
