import { Injectable } from '@angular/core';
import {ApiHttpService, ApiIndexResult, ApiListResult, ApiSingleResult, ListOptions} from '@capturum/api';
import { MapItem } from '@capturum/ui/api';
import { CategoryMapItem } from '@core/models/category-map-item.model';
import { ResourceApiService } from '@core/services/resource-api.service';
import { TranslateService } from '@ngx-translate/core';
import { TimeSlotMapItem } from '@src/app/core/models/timeslot-map-item.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from '../../device/models/device.model';
import { TimeSlot } from '../../timeslot/models/timeslot.model';
import { Domain } from '../models/domain.model';

@Injectable({
  providedIn: 'root',
})
export class DomainService extends ResourceApiService<Domain> {
  protected endpoint = 'domain';

  private domainsUpdated$: Subject<void> = new Subject<void>();

  constructor(public apiHttp: ApiHttpService, private translateService: TranslateService) {
    super(apiHttp);
  }

  /**
   * Get domainsUpdated as observable
   */
  public getDomainsUpdated(): Observable<void> {
    return this.domainsUpdated$.asObservable();
  }

  /**
   * Emit domainsUpdated
   *
   * @return void
   */
  public setDomainsUpdated(): void {
    this.domainsUpdated$.next();
  }

  /**
   * Get the portal page
   *
   * @return Observable<Domain>
   */
  public getPortal(): Observable<Domain> {
    return this.apiHttp.get('/portal').pipe(
      map((response: ApiSingleResult<Domain>) => {
        return response?.data;
      }),
    );
  }

  /**
   * Store files for domain
   *
   * @param domainId: string
   * @param files: { id: string, mimeType: string, filename: string, is_public: boolean }[]
   */
  public storeFiles(
    domainId: string,
    files: { id: string; mimeType: string; filename: string; is_public: boolean }[],
  ): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}/${domainId}/file`, { files });
  }

  /**
   * Delete files for domain
   *
   * @param domainId: string
   * @return  Observable<void>
   */
  public deleteFiles(fileId: string): Observable<void> {
    return this.apiHttp.delete(`/file/${fileId}`);
  }

  /**
   * Restore a domain
   *
   * @param domainId: string
   * @return Observable<void>
   */
  public restore(domainId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${domainId}/restore`, {});
  }

  public getDomainList(options: ListOptions = { perPage: 9999 }): Observable<ApiIndexResult<MapItem>> {
    return this.apiHttp.get(`/${this.endpoint}/list`);
  }

  public getAllDomainsList(options: ListOptions = { perPage: 50 }): Observable<ApiIndexResult<MapItem>> {
    return this.apiHttp.get(`/${this.endpoint}` + this.getOptionsQuery(options));
  }

  public getDomainCategoriesList(id: string): Observable<ApiIndexResult<CategoryMapItem>> {
    return this.apiHttp.get(`/${this.endpoint}/${id}/category/list`);
  }

  public getDomainTimeSlotlist(
    domainId: string,
    options: ListOptions = { include: ['timeslots'] },
  ): Observable<TimeSlotMapItem[]> {
    return this.apiHttp
      .get<ApiSingleResult<Device>>(`/${this.endpoint}/${domainId}` + this.getOptionsQuery(options))
      .pipe(
        map((result: ApiSingleResult<Device>) => {
          return result.data.timeslots;
        }),
        map((timeSlots: TimeSlot[]) => {
          return timeSlots.map((timeSlot: TimeSlot) => {
            const timeStart = timeSlot.time_start.substring(0, timeSlot.time_start.length - 3);
            const timeEnd = timeSlot.time_end.substring(0, timeSlot.time_end.length - 3);
            const label = `${timeStart} - ${timeEnd} ${this.translateService.instant('radboud.manage.timeslot.max', {
              hours: timeSlot?.booking_time_max ? timeSlot?.booking_time_max : '-',
            })} / ${this.translateService.instant('radboud.manage.timeslot.min', {
              hours: timeSlot?.booking_time_min ? timeSlot?.booking_time_min : '-',
            })} / ${this.translateService.instant('radboud.manage.timeslot.timeblock-minutes.label')}: ${
              timeSlot.timeblock_minutes ? timeSlot.timeblock_minutes : '-'
            }`;

            return {
              is_default_for_domain: timeSlot.is_default_for_domain,
              value: timeSlot.id,
              label,
            };
          }) as unknown as TimeSlotMapItem[];
        }),
      );
  }
}
