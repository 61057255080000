<div class="device-card">
  <ng-container [ngTemplateOutlet]="deviceCardType === cardTypes.expanded ? expandedCardTemplate : compactCardTemplate">
  </ng-container>

  <ng-template #expandedCardTemplate>
    <div class="device-card__expanded">
      <div class="header">
        <div class="header__icons">
          <div class="d-flex">
            <ng-container [ngSwitch]="device?.status?.value">
              <i *ngSwitchCase="deviceStatuses.green" class="status-icon color-success fas fa-check-circle"></i>
              <i *ngSwitchCase="deviceStatuses.yellow" class="status-icon color-warning fas fa-times-circle"></i>
              <i *ngSwitchCase="deviceStatuses.red" class="status-icon color-danger fas fa-times-circle"></i>
            </ng-container>

            <div *ngIf="device?.srf_device" class="srf-device ml-2">
              {{ 'radboud.device.srf.label' | translate }}
            </div>
          </div>

          <ng-container *ngIf="device?.admin_authorized">
            <div class="edit-button">
              <cap-button
                (click)="editDevice(device)"
                icon="fas fa-pencil"
                styleClass="primary cap-rounded-icon-button cap-icon-button-small">
              </cap-button>
            </div>
          </ng-container>
        </div>

        <div class="header__image">
          <img [src]="device | deviceImage | default: ('placeholder.png' | assets)" [alt]="device?.short_name" />
        </div>
      </div>

      <div class="content">
        <div class="domain">
          {{ 'radboud.domain.title' | translate }}:
          <p class="color-primary">
            {{ device?.domain?.name }}
          </p>
        </div>
        <div class="title">
          {{ device?.short_name || '-' }}
        </div>
        <div class="description">
          <p>{{ device?.full_name || '-' }}</p>
        </div>

        @if (device?.message && (device | deviceShowMessage) && showMessage) {
          <div class="message">
            {{ device?.message }}
          </div>
        }
      </div>

      <div class="footer">
        <div class="button-wrapper">
          <ng-container
            *ngIf="
              device?.status?.value !== deviceStatuses.red ||
              (device?.status?.value === deviceStatuses.red &&
                allowBookingOfDevicesWithAnyStatus &&
                (device?.admin_authorized || device?.authorized))
            ">
            <cap-button
              *ngIf="isAuthenticated && device?.authorized"
              (click)="routeToBooking(device)"
              [label]="
                (device.is_sample_listing
                  ? 'radboud.button.sample-submission.label'
                  : 'radboud.button.book-device.label'
                ) | translate
              "
              styleClass="primary cap-rounded">
            </cap-button>
          </ng-container>

          <cap-button
            *ngIf="!isAuthenticated && device?.status?.value !== deviceStatuses.red"
            (click)="openLoginModal(device?.id)"
            [label]="'radboud.button.sign-in-for-booking.label' | translate"
            styleClass="primary cap-rounded"
            icon="fas fa-sign-in"
            iconPos="right">
          </cap-button>
        </div>

        <div class="button-wrapper">
          <cap-button
            [label]="'radboud.button.show-details.label' | translate"
            (click)="openDeviceDetails(device)"
            styleClass="secondary cap-rounded"
            icon="fas fa-chevron-right"
            iconPos="right">
          </cap-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #compactCardTemplate>
    <div class="device-card__compact">
      <div class="compact-image">
        <img [src]="device | deviceImage | default: ('placeholder.png' | assets)" [alt]="device?.short_name" />
      </div>

      <div class="compact-content">
        <div class="compact-content__icon">
          <ng-container [ngSwitch]="device?.status?.value">
            <i *ngSwitchCase="deviceStatuses.green" class="status-icon color-success fas fa-check-circle"></i>
            <i *ngSwitchCase="deviceStatuses.yellow" class="status-icon color-warning fas fa-times-circle"></i>
            <i *ngSwitchCase="deviceStatuses.red" class="status-icon color-danger fas fa-times-circle"></i>
          </ng-container>
        </div>

        <div class="domain">
          {{ 'radboud.domain.title' | translate }}:
          <p class="color-primary">{{ device?.domain?.name }}</p>
        </div>
        <div class="title">
          {{ device?.full_name || '-' }}
        </div>
        <div class="description">
          <p>{{ device?.description || '-' | stripHtml }}</p>
        </div>

        <div class="compact-content__footer">
          <cap-button
            [label]="'radboud.button.show-details.label' | translate"
            (click)="openDeviceDetails(device)"
            styleClass="primary cap-rounded cap-small"
            icon="fas fa-chevron-right"
            iconPos="right">
          </cap-button>

          <ng-container *ngIf="device?.admin_authorized">
            <cap-button
              (click)="editDevice(device)"
              icon="fas fa-pencil"
              styleClass="secondary cap-rounded-icon-button cap-icon-button-small">
            </cap-button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
