import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@capturum/auth';
import { CustomModuleService } from '../services/custom-module.service';

@Injectable()
export class ModuleResolver {
  constructor(private moduleService: CustomModuleService, private authService: AuthService) {}

  /**
   * Resolve the modules
   *
   * @param route: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   * @return Observable<boolean>
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.moduleService.loadModules(this.authService.isAuthenticated());
  }
}
