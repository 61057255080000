import { QuillConfig } from 'ngx-quill';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { editorSettings } from '@src/app/core/consts/editor.const';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { NgIf } from '@angular/common';

@Component({
  template: `
    <ng-container *ngIf="to.disabled">
      <div class="like-textarea" [innerHTML]="formControl.value"></div>
    </ng-container>
    <ng-container *ngIf="!to.disabled">
      <cap-editor
        [formlyAttributes]="field"
        [formControl]="formControl"
        [required]="to.required || false"
        [class.ng-dirty]="showError"
        [class.editor-disabled]="to.disabled"
        [config]="to.config || editorConfig">
      </cap-editor>
    </ng-container>
  `,
  styles: [
    '.like-textarea { background: #fafafa; padding: 10px; border: 1px solid #ced4da; border-radius: 3px; font-size: 0.875rem; min-height: 200px; }',
  ],
  standalone: true,
  imports: [NgIf, CapturumEditorModule, FormlyModule, FormsModule, ReactiveFormsModule],
})
export class EditorFormlyTypeComponent extends FieldType {
  public editorConfig: QuillConfig = editorSettings;
}
