<div class="form-group" [class.has-error]="showError">
  <label *ngIf="props.translateLabel && props.hideLabel !== true" [attr.for]="id">
    {{ props.translateLabel | observablePipe | async }}

    <span *ngIf="props.required && props.hideRequiredMarker !== true">*</span>

    <i
      *ngIf="props.labelIcon"
      [class]="props.labelIcon.icon"
      [pTooltip]="props.labelIcon.tooltip | observablePipe | async"
      [tooltipPosition]="props.labelIcon.tooltipPosition || 'top'">
    </i>
  </label>

  <ng-template #fieldComponent></ng-template>

  <small *ngIf="props.description" class="form-text text-muted">{{ props.description | observablePipe | async }}</small>
</div>
