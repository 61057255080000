<p-sidebar
  [(visible)]="visible"
  styleClass="radboud-right-sidebar"
  position="right"
  [showCloseIcon]="false"
  (onHide)="hide()">
  <div class="radboud-right-sidebar__header">{{ title }} <i class="fas fa-times" (click)="hide()"></i></div>

  <div class="radboud-right-sidebar__content">
    <ng-container *ngIf="childComponentType; else defaultType">
      <ng-template capDynamicDialogContent></ng-template>
    </ng-container>
  </div>

  <ng-template #defaultType>
    <ng-content></ng-content>
  </ng-template>

  <div class="radboud-right-sidebar__footer" *ngIf="showButtons">
    <cap-button styleClass="secondary" [label]="cancelButtonTranslationKey | translate" (click)="cancel()">
    </cap-button>

    <cap-button
      styleClass="success"
      [label]="submitButtonTranslationKey | translate"
      icon="fas fa-save"
      (click)="submit()"
      [disabled]="submitButtonDisabled">
    </cap-button>
  </div>
</p-sidebar>
