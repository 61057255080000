import { Injectable } from '@angular/core';
import { ApiHttpService, ListOptions } from '@capturum/api';
import { BatchStatusService, FinishedBatchStatus } from '@capturum/complete';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  filter,
  interval,
  merge,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ResourceApiService } from './resource-api.service';

export interface FinishedExportJobStatus {
  finished: boolean;
  data: FinishedEportJob;
}

export interface FinishedEportJob {
  file_path: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExportJobService<T = any> extends ResourceApiService<T> {
  protected endpoint = 'export-job';

  private defaultInterval = 3000;
  private stopExportJobSubject = new Subject<void>();
  private exportJobSubscription: Subscription;

  constructor(public apiHttp: ApiHttpService, private batchStatusService: BatchStatusService) {
    super(apiHttp);
  }

  public getExportJobStatus(url: string): Observable<FinishedEportJob> {
    return this.apiHttp.get(url);
  }

  public exportJob(url: string, options?: { interval: number }): Observable<FinishedExportJobStatus> {
    const subject = new BehaviorSubject<FinishedExportJobStatus>({
      finished: false,
      data: null,
    });

    this.exportJobSubscription = this.getExportJobStatus(url)
      .pipe(
        switchMap((response: FinishedEportJob) => {
          return this.startInterval(url, options?.interval);
        }),
        takeUntil(
          merge(
            subject.asObservable().pipe(
              filter((status) => {
                return status.finished === true;
              }),
            ),
            this.stopExportJobSubject.asObservable(),
          ),
        ),
      )
      .subscribe({
        next: (response) => {
          subject.next({
            finished: !!response?.file_path,
            data: response,
          });
        },
        error: (error) => {
          subject.error(error.message);
        },
      });

    return subject.asObservable();
  }

  public exportBatchJob(
    url: string,
    options?: ListOptions,
    messageKey = 'toast.success.title',
    showSuccessfulMessage = true,
    executeAction = true,
  ): Observable<FinishedBatchStatus> {
    return this.apiHttp.get<{ export_id: string }>(`/${this.endpoint}/${url}${this.getOptionsQuery(options)}`).pipe(
      switchMap((response) => {
        return this.batchStatusService.getIsUpdatedBatch(
          response.export_id,
          showSuccessfulMessage,
          messageKey,
          executeAction,
        );
      }),
    );
  }

  public stopExportJob(): void {
    this.stopExportJobSubject.next();

    if (this.exportJobSubscription && !this.exportJobSubscription.closed) {
      this.exportJobSubscription.unsubscribe();
    }
  }

  private startInterval(url: string, intervalAmount = this.defaultInterval): Observable<FinishedEportJob> {
    return interval(intervalAmount ?? this.defaultInterval).pipe(
      switchMap(() => {
        return this.getExportJobStatus(url);
      }),
    );
  }
}
