<div class="form-group" [class.has-error]="showError">
  <label *ngIf="to.translateLabel && to.hideLabel !== true" [attr.for]="id">
    {{ to.translateLabel | observablePipe | async }}

    <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>

    <i
      *ngIf="to.labelIcon"
      [class]="to.labelIcon.icon"
      [pTooltip]="to.labelIcon.tooltip | observablePipe | async"
      [tooltipPosition]="to.labelIcon.tooltipPosition || 'top'">
    </i>
  </label>

  <ng-template #fieldComponent></ng-template>

  <small *ngIf="to.description" class="form-text text-muted">{{ to.description | observablePipe | async }}</small>
</div>
