import { NavigationButton } from '@core/models/navigation-button.model';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ButtonMenuItem } from '@core/models/button-menu-item.model';
import { ManageHeaderButton } from '@core/models/manage-header-button.model';
import { CapturumTemplateDirective, CapturumSharedModule } from '@capturum/ui/api';
import { NavigationButtonComponent } from '../navigation-button/navigation-button.component';
import { MenuButtonComponent } from '../menu-button/menu-button.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { NgIf, NgTemplateOutlet, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-manage-header',
  templateUrl: './manage-header.component.html',
  styleUrls: ['./manage-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CapturumSkeletonModule,
    NgTemplateOutlet,
    NgFor,
    NgxPermissionsModule,
    CapturumButtonModule,
    MenuButtonComponent,
    NavigationButtonComponent,
    AsyncPipe,
    CapturumSharedModule,
  ],
})
export class ManageHeaderComponent implements AfterContentInit {
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  @Input() public heading: string;
  @Input() public showBack = true;
  @Input() public loading = false;
  @Input() public isExporting = false;
  @Input() public buttons?: ManageHeaderButton[];
  @Input() public menuItems?: ButtonMenuItem[];
  @Input() public menuVisible = true;
  @Input() public navigationVisible = false;
  @Input() public navigationButton?: NavigationButton[];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onBackClick: EventEmitter<void> = new EventEmitter<void>();

  public extraContentTemplate: TemplateRef<any>;

  public ngAfterContentInit(): void {
    this.extraContentTemplate = this.templates.find((template) => {
      return template.getType() === 'extraContent';
    })?.template;
  }
}
