import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlySelectOptionsPipe } from '@src/app/shared/pipes/select-options-pipe.pipe';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-custom-dropdown',
  template: `
    <cap-dropdown
      [options]="props.options | formlySelectOptions : field | async"
      [formlyAttributes]="field"
      [formControl]="innerControl"
      [placeholder]="props.translatePlaceholder || props.placeholder | observablePipe | async"
      [filterPlaceholder]="props.filterPlaceholder || props.placeholder | observablePipe | async"
      [floatLabel]="(props.label_position | observablePipe | async) === 'floating'"
      [label]="(props.label_position | observablePipe | async) === 'floating' ? props.label : ''"
      [style]="props.style"
      [panelStyle]="props.panelStyle"
      [styleClass]="props.styleClass"
      [panelStyleClass]="props.panelStyleClass"
      [sortAlphabetically]="props.sortAlphabetically"
      [sortBy]="props.sortBy"
      [filterBy]="props.filterBy"
      [emptyFilterMessage]="props.emptyFilterMessage"
      [scrollHeight]="props.scrollHeight"
      [disabled]="props.isDisabled || props.disabled"
      [name]="props.name"
      [inputId]="props.inputId"
      [selectId]="props.selectId"
      [dataKey]="props.dataKey"
      [readonly]="props.readonly"
      [autofocus]="props.autofocus"
      [resetFilterOnHide]="props.resetFilterOnHide"
      [dropdownIcon]="props.dropdownIcon"
      [optionLabel]="props.optionLabel"
      [autoDisplayFirst]="props.autoDisplayFirst"
      [showClear]="props.showClear"
      [group]="props.group"
      [optionValue]="props.optionValue"
      [ariaFilterLabel]="props.ariaFilterLabel"
      [withImages]="props.withImages"
      [openOnFocus]="props.openOnFocus"
      [hasTooltip]="props.hasTooltip"
      [editable]="props.editable"
      [virtualScroll]="props.virtualScroll || defaultOptions.props.virtualScroll"
      [filter]="props.filter || defaultOptions.props.filter"
      [appendTo]="props.appendTo"
      [autofocusFilter]="props.autofocusFilter"
      [virtualScrollItemSize]="props.virtualScrollItemSize || defaultOptions.props.virtualScrollItemSize"
      (changeSelection)="props?.changeSelection ? props?.changeSelection(field, $event) : null">
    </cap-dropdown>
  `,
  standalone: true,
  imports: [
    CapturumDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    FormlySelectOptionsPipe,
    CapturumSharedModule,
  ],
})
export class DropdownFormlyTypeComponent extends FieldType implements OnInit {
  public innerControl: FormControl<string>;
  public defaultOptions = {
    props: {
      sortAlphabetically: true,
      sortBy: 'label',
      filterBy: 'label',
      filter: true,
      virtualScroll: true,
      showClear: false,
      virtualScrollItemSize: 30,
      emptyFilterMessage: 'No results found',
      scrollHeight: '200px',
      name: '',
      inputId: '',
      selectId: '',
      dataKey: '',
      style: null,
      panelStyle: null,
      styleClass: '',
      panelStyleClass: '',
      editable: false,
      autofocus: false,
      resetFilterOnHide: false,
      dropdownIcon: 'pi pi-chevron-down',
      optionLabel: 'label',
      autoDisplayFirst: true,
      group: false,
      ariaFilterLabel: '',
      withImages: false,
      openOnFocus: false,
      hasTooltip: false,
      optionValue: 'value',
      autofocusFilter: true,
    },
  };

  private destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.innerControl = new FormControl<string>(null);

    this.formControl.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.formControl.errors?.required) {
        this.innerControl.markAsTouched();
        this.innerControl.setValidators(Validators.required);
        this.innerControl.setErrors({ required: true });
      } else {
        this.innerControl.setErrors(null);
        this.innerControl.clearValidators();
      }
    });

    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.innerControl.setValue(value, {
        emitEvent: false,
        onlySelf: true,
      });
    });

    this.innerControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.formControl.setValue(value);
    });
  }
}
