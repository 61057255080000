<div class="page-wrapper__content {{ styleClass }}">
  <ng-container *ngIf="loading; else content">
    <div class="d-flex flex-row flex-nowrap">
      <div class="mr-sm-0 mr-md-4 left-layout-{{ layout }}">
        <cap-skeleton width="100%" height="600px"></cap-skeleton>
      </div>

      <div class="ml-sm-0 ml-md-4 right-layout-{{ layout }}">
        <cap-skeleton width="100%" height="600px"></cap-skeleton>
      </div>
    </div>
  </ng-container>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</div>
