import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CapturumButtonModule } from '@capturum/ui/button';
import { DialogStateService } from '@core/services/dialog-state.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Observable } from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    RouterOutlet,
    ConfirmDialogModule,
    SharedModule,
    CapturumButtonModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class BasicLayoutComponent {
  public isDialogOpen$: Observable<boolean>;

  constructor(private translateService: TranslateService, private dialogStateService: DialogStateService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    this.isDialogOpen$ = this.dialogStateService.getDialogState();
  }
}
