import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '@features/device/models/device.model';

@Pipe({
  name: 'deviceImage',
  standalone: true,
})
export class DeviceImagePipe implements PipeTransform {
  public transform(device: Device): string {
    return device?.images && device?.images[0] ? device.images[0]?.public_url : null;
  }
}
